import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { getTeamImage } from '../../utils/media';
import { AssetType } from '../../types/assetType';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import styled from '@emotion/styled';
import Player from 'video.js/dist/types/player';
import { stop as stopRadio } from '../../redux/modules/radio';
import { FeedUrls } from '../../types/video';
import { ToastContainerIds, VIDEO_FEEDS } from '../../constants/Global.constants';
import { POLL, TRIVIA, DJ } from '../../constants/Pusher.constants';
import HTToastContainer from '../toast/HTToastContainer';
import useToast from '../../hooks/useToast';
import { trackEventData } from '../../utils/analytics';
import { NAVIGATE_VIDEO_4UP_FRAME, NAVIGATE_VIDEO_MAIN_FEED } from '../../constants/analytics.constants';
import { useParams } from 'react-router';
import routes from '../../constants/routes.constants';
import HTImage from './HTImage';
import { clearNotification } from '../../redux/modules/interaction-notification';

const FourUpOverlay = styled.div`
    position: absolute;
    display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
`;

const FeedRow = styled.div`
  position: relative;
  display: flex;
  flex: 1 1;
`;

const FeedQuadrant = styled.div`
  display: flex;
  flex: 1 1;
`;

const videoStyles = {
  height: '100%',
  width: '100%',
  backgroundColor: 'black',
};

type VideoProps = {
  joinedTeamId: string;
  type: string;
};

const VideoPlayer = ({joinedTeamId, type}: VideoProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const videoRef = useRef<any>(null);
  const toast = useToast();

  const {activeTeam} = useSelector((state: RootState) => state.teams);
  const {geolocating} = useSelector((state: RootState) => state.geolocation);
  const {isPlaying: isRadioPlaying} = useSelector((state: RootState) => state.radio);
  const {isInHomeStadium} = useSelector((state: RootState) => state.geolocation);
  const {hasJoinedHomeGame, gameId} = useSelector((state: RootState) => state.game);
  const interaction = useSelector((state: RootState) => state.interaction);
  const notification = useSelector((state: RootState) => state.interactionNotification);

  const { feedName } = useParams();

  const [player, setPlayer] = useState<Player | null>(null);
  const [isVideoMuted, setIsVideoMuted] = useState(true);
  const [mainFeed, setMainFeed] = useState<string | null>(null);
  const [currentFeed, setCurrentFeed] = useState<string | null>(null);

  // Map feeds from API (array) to an object with fixed keys for easier handling downstream
  const feedUrls: FeedUrls | null = useMemo(() => {
    if (!activeTeam?.feeds) {
      return null;
    }

    return Object.entries(VIDEO_FEEDS).reduce((feeds, entry) => {
      return {
        ...feeds,
        [entry[0]]:
          activeTeam.feeds.find((feed: { feed_name: string, feed_url: string }) => feed.feed_name === entry[1])?.feed_url || null
      }
    }, {} as FeedUrls);
  }, [activeTeam]);

  const mainFeedIs4Up = mainFeed === feedUrls?.FourUp;

  // Determine main feed (either default or 4Up) - determines whether it's In Stadium Feed or At Home Feed
  const getMainFeed = useCallback(() => {
    if (!feedUrls) return null;

    const shouldShow4upFeed = hasJoinedHomeGame && isInHomeStadium;
    const hasAllFeedsFor4UpView = !!feedUrls.FourUp && !!feedUrls.Feed1 && !!feedUrls.Feed2 && !!feedUrls.Feed3 && !!feedUrls.Stats;

    // NOTE: This determines whether the in game feed is shown or the At Home (not in stadium) feed, which is Default
    return shouldShow4upFeed && hasAllFeedsFor4UpView ? feedUrls.FourUp : !!feedUrls.Default ? feedUrls.Default : null;
  }, [feedUrls, hasJoinedHomeGame, isInHomeStadium]);

  const teamLogo = getTeamImage({
    type: AssetType.TeamLogo,
    teamId: joinedTeamId,
  });

  // Use this if reinstating At Home Feed Not Available image
  // const feedNotAvailableImage = getTeamImage({
  //   type: AssetType.FeedNotAvailableImage,
  //   teamId: joinedTeamId
  // });

  // Initialize Feeds
  useEffect(() => {
    if (feedUrls && !mainFeed && !currentFeed && !geolocating) {
      const _mainFeed = getMainFeed();
      setMainFeed(_mainFeed);
      setCurrentFeed(_mainFeed);
    }
  }, [getMainFeed, feedUrls, mainFeed, currentFeed, geolocating]);

  // Initialize Player (with main feed)
  useEffect(() => {
    if (!videoRef || !mainFeed || geolocating || player) return;

    const sources = [
      {
        src: mainFeed,
        type,
      },
    ];

    const controlBar = {
      fullscreenToggle: true,
      playToggle: false,
      progressControl: false,
      pictureInPictureToggle: false,
      remainingTimeDisplay: false,
      volumeMenuButton: true,
      skipButtons: {
        backward: 10,
      },
    };

    const _player = videojs(videoRef.current, {
      fluid: true,
      autoplay: 'muted',
      controls: mainFeed !== feedUrls?.FourUp,
      liveui: mainFeed !== feedUrls?.FourUp,
      loop: true,
      sources,
      poster: teamLogo?.mediaUrl,
      controlBar,
      errorDisplay: false,
      preferFullWindow: true, // allows us to display toasts/overlays etc over full "screen" (window) video in iOS
    });

    _player.ready(() => {
      setPlayer(_player);
    })

  }, [player, teamLogo?.mediaUrl, type, mainFeed, feedUrls, geolocating]);

  useEffect(() => {
    console.log(`mute changed: isVideoMuted=${isVideoMuted}`);
  }, [isVideoMuted]);

  useEffect(() => {
    
    return () => {
      if (player) {
        player.dispose();
      }
    };
  }, [player]);

  const muteButtonEventHandler = useCallback((e: any) => {
    const muted = videoRef.current?.getAttribute('muted');
    if (muted !== 'muted') {
      // Video mute button was toggled off; mute radio if it is playing
      if (isRadioPlaying) {
        dispatch(stopRadio());
      }
      setIsVideoMuted(false);
    } else {
      setIsVideoMuted(true);
    }
  }, [isRadioPlaying, videoRef, dispatch]);

  // Detect volume change for mute button side effects
  useEffect(() => {
    player?.on('volumechange', muteButtonEventHandler);
    player?.on('loadeddata', () => {
      player?.play()?.catch((e) => {
        console.log(e);
      });
    });
    if (player && player.paused() && player.isReady_) {
      player.play()?.catch((e) => {
        console.log(e);
      });
    }
    return () => player?.off('volumechange', muteButtonEventHandler);
  }, [player, muteButtonEventHandler]);

  // Mute video if radio audio is toggled on
  useEffect(() => {
    if (isRadioPlaying) {
      setIsVideoMuted(true);
    }
  }, [isRadioPlaying]);

  // 4Up: Set player source to currentFeed & open full screen/window when currentFeed changes
  // to a quadrant's video feed
  useEffect(() => {
    if (!mainFeedIs4Up) return;

    if (player && currentFeed && currentFeed !== mainFeed) {
      player.src({ type, src: currentFeed });
      player.options({ liveui: true });
      player.controls(true);
      player.requestFullscreen({
        navigationUI: 'show'
      });
      player.play()?.catch((e) => {
        console.log(e);
      });
    }
  }, [mainFeedIs4Up, currentFeed, mainFeed, type, player]);

  // 4Up: Listen for exit of full window/screen view to reset video to main feed (4-up)
  useEffect(() => {
    if (!mainFeedIs4Up || !player) return;

    const resetToMainFeed = () => {
      trackEventData(NAVIGATE_VIDEO_MAIN_FEED, {mainFeed, isInHomeStadium})
      setCurrentFeed(mainFeed);
      player.src({ type, src: mainFeed });
      player.controls(false);
      player.play()?.catch((e) => {
        console.log(e);
      });
    };

    const onFullscreenchange = (e: any) => {
      if (!player.isFullscreen() && gameId) {
        resetToMainFeed();
        navigate(routes.inGame.replace(':gameId', gameId));
      }
    };

    player.on('fullscreenchange', onFullscreenchange);
    player.on('exitFullWindow', resetToMainFeed);

    return () => {
      player.off('exitFullWindow', resetToMainFeed);
      player.off('fullscreenchange', onFullscreenchange);
    }
  }, [mainFeedIs4Up, player, type, mainFeed, feedUrls, navigate, gameId, isInHomeStadium]);

  // 4Up quadrant click handler
  const onClickFeedQuadrant = useCallback((feed: string) => {
    if (!mainFeedIs4Up) return;

    if (feedUrls) {
      trackEventData(NAVIGATE_VIDEO_4UP_FRAME, {frame: feed, isInHomeStadium});
      //@ts-ignore
      navigate(`${routes.inGame.replace(':gameId',gameId)}/video/${feed}`);
    }
  }, [mainFeedIs4Up, feedUrls, isInHomeStadium, navigate, gameId]);



  // Exit fullscreen video and dismiss toast when toast's 'go to trivia/poll' button is tapped
  const onClickCta = useCallback(() => {
    if (gameId) {
      if (player?.isFullWindow) {
        player.exitFullWindow();
        toast.dismissAllToasts();
      }
      if (player?.isFullscreen()) {
        player.exitFullscreen();
        toast.dismissAllToasts();
      }
      navigate(routes.inGame.replace(':gameId',gameId));
    }
  }, [player, navigate, gameId, toast]);

  // Display a toast when user is in fullscreen video and a trivia or poll is starting
  useEffect(() => {
    if (
      interaction.visible &&
      (interaction.interactionType === POLL || interaction.interactionType === TRIVIA || interaction.interactionType === DJ) &&
      !!player &&
      (player.isFullWindow || player.isFullscreen())) {
        console.log('got interaction in fullscreen');
        toast.interactionToast(
          {
            message: `A ${interaction.interactionType} is starting, exit full screen video to participate!`,
            ctaLabel: `Go to ${interaction.interactionType}`,
            onClickCta: onClickCta
          }, {
            containerId: ToastContainerIds.Video,
          }
        );
      }
  }, [interaction, player, toast, onClickCta]);

  useEffect(() => {
    if (notification.body) {
      console.log('got notification for toast');
      toast.interactionToast(
            {
              message: notification.body,
              hasCta: false,
              onDismiss: () => {
                console.log('onDismiss');
                dispatch(clearNotification())
              }
            }, {
              containerId: ToastContainerIds.Video,
            }
          );
    }
  }, [notification, toast, dispatch]);

  useEffect(() => {
    if (feedName && feedUrls) {
      //@ts-ignore
      setCurrentFeed(feedUrls[feedName]);
    } else if (!!mainFeed) {
      if (player?.isFullWindow) {
        player.exitFullWindow();
      }
      if (player?.isFullscreen()) {
        player.exitFullscreen();
      }
      player?.controls(isInHomeStadium ? false : true);
      setCurrentFeed(mainFeed);
    }
  },[feedName, feedUrls, mainFeed, player, isInHomeStadium]);

  return (
    <>
    {!currentFeed && !isInHomeStadium && 
    <div data-vjs-player>
      <HTImage
        type={AssetType.FeedNotAvailableImage}
        teamId={joinedTeamId}
        altText='Feed Not Available'
      />
    </div>}
    {currentFeed && <div data-vjs-player>
      <HTToastContainer containerId={ToastContainerIds.Video} />
      {mainFeedIs4Up && currentFeed === mainFeed && (
        <FourUpOverlay>
          <FeedRow>
            <FeedQuadrant onClick={(e) => {
              e.preventDefault();
              onClickFeedQuadrant('Feed1')
            }} />
            <FeedQuadrant onClick={(e) => {
              e.preventDefault();
              onClickFeedQuadrant('Feed2')
            }} />
          </FeedRow>
          <FeedRow>
            <FeedQuadrant onClick={(e) => {
              e.preventDefault();
              onClickFeedQuadrant('Feed3')
            }} />
            <FeedQuadrant onClick={(e) => {
              e.preventDefault();
              onClickFeedQuadrant('Stats')
            }} />
          </FeedRow>
        </FourUpOverlay>
      )}
      <video
        ref={videoRef}
        className="video-js"
        style={videoStyles}
        muted={isVideoMuted}
        playsInline
      />
    </div>}
  </>
  );
};

export default VideoPlayer;
